/**
 * Do not edit directly
 * Generated on Wed Dec 11 2024 08:21:42 GMT+0000 (Coordinated Universal Time)
 */

.Color-foreground-gray-50 {
	color: var(--color-gray-50);
}

.Color-foreground-gray-100 {
	color: var(--color-gray-100);
}

.Color-foreground-gray-200 {
	color: var(--color-gray-200);
}

.Color-foreground-gray-300 {
	color: var(--color-gray-300);
}

.Color-foreground-gray-400 {
	color: var(--color-gray-400);
}

.Color-foreground-gray-500 {
	color: var(--color-gray-500);
}

.Color-foreground-gray-600 {
	color: var(--color-gray-600);
}

.Color-foreground-gray-700 {
	color: var(--color-gray-700);
}

.Color-foreground-gray-800 {
	color: var(--color-gray-800);
}

.Color-foreground-gray-900 {
	color: var(--color-gray-900);
}

.Color-foreground-gray-1000 {
	color: var(--color-gray-1000);
}

.Color-foreground-gray-1100 {
	color: var(--color-gray-1100);
}

.Color-foreground-gray-000 {
	color: var(--color-gray-000);
}

.Color-foreground-neutral-50 {
	color: var(--color-neutral-50);
}

.Color-foreground-neutral-100 {
	color: var(--color-neutral-100);
}

.Color-foreground-neutral-200 {
	color: var(--color-neutral-200);
}

.Color-foreground-neutral-300 {
	color: var(--color-neutral-300);
}

.Color-foreground-neutral-400 {
	color: var(--color-neutral-400);
}

.Color-foreground-neutral-500 {
	color: var(--color-neutral-500);
}

.Color-foreground-neutral-600 {
	color: var(--color-neutral-600);
}

.Color-foreground-neutral-700 {
	color: var(--color-neutral-700);
}

.Color-foreground-neutral-800 {
	color: var(--color-neutral-800);
}

.Color-foreground-neutral-900 {
	color: var(--color-neutral-900);
}

.Color-foreground-neutral-1000 {
	color: var(--color-neutral-1000);
}

.Color-foreground-neutral-1100 {
	color: var(--color-neutral-1100);
}

.Color-foreground-neutral-000 {
	color: var(--color-neutral-000);
}

.Color-foreground-indigo-50 {
	color: var(--color-indigo-50);
}

.Color-foreground-indigo-100 {
	color: var(--color-indigo-100);
}

.Color-foreground-indigo-200 {
	color: var(--color-indigo-200);
}

.Color-foreground-indigo-300 {
	color: var(--color-indigo-300);
}

.Color-foreground-indigo-400 {
	color: var(--color-indigo-400);
}

.Color-foreground-indigo-500 {
	color: var(--color-indigo-500);
}

.Color-foreground-indigo-600 {
	color: var(--color-indigo-600);
}

.Color-foreground-indigo-700 {
	color: var(--color-indigo-700);
}

.Color-foreground-indigo-800 {
	color: var(--color-indigo-800);
}

.Color-foreground-indigo-900 {
	color: var(--color-indigo-900);
}

.Color-foreground-indigo-1000 {
	color: var(--color-indigo-1000);
}

.Color-foreground-indigo-1100 {
	color: var(--color-indigo-1100);
}

.Color-foreground-navy-50 {
	color: var(--color-navy-50);
}

.Color-foreground-navy-100 {
	color: var(--color-navy-100);
}

.Color-foreground-navy-200 {
	color: var(--color-navy-200);
}

.Color-foreground-navy-300 {
	color: var(--color-navy-300);
}

.Color-foreground-navy-400 {
	color: var(--color-navy-400);
}

.Color-foreground-navy-500 {
	color: var(--color-navy-500);
}

.Color-foreground-navy-600 {
	color: var(--color-navy-600);
}

.Color-foreground-navy-700 {
	color: var(--color-navy-700);
}

.Color-foreground-navy-800 {
	color: var(--color-navy-800);
}

.Color-foreground-navy-900 {
	color: var(--color-navy-900);
}

.Color-foreground-navy-1000 {
	color: var(--color-navy-1000);
}

.Color-foreground-navy-1100 {
	color: var(--color-navy-1100);
}

.Color-foreground-critical-50 {
	color: var(--color-critical-50);
}

.Color-foreground-critical-100 {
	color: var(--color-critical-100);
}

.Color-foreground-critical-200 {
	color: var(--color-critical-200);
}

.Color-foreground-critical-300 {
	color: var(--color-critical-300);
}

.Color-foreground-critical-400 {
	color: var(--color-critical-400);
}

.Color-foreground-critical-500 {
	color: var(--color-critical-500);
}

.Color-foreground-critical-600 {
	color: var(--color-critical-600);
}

.Color-foreground-critical-700 {
	color: var(--color-critical-700);
}

.Color-foreground-critical-800 {
	color: var(--color-critical-800);
}

.Color-foreground-critical-900 {
	color: var(--color-critical-900);
}

.Color-foreground-critical-1000 {
	color: var(--color-critical-1000);
}

.Color-foreground-critical-1100 {
	color: var(--color-critical-1100);
}

.Color-foreground-success-50 {
	color: var(--color-success-50);
}

.Color-foreground-success-100 {
	color: var(--color-success-100);
}

.Color-foreground-success-200 {
	color: var(--color-success-200);
}

.Color-foreground-success-300 {
	color: var(--color-success-300);
}

.Color-foreground-success-400 {
	color: var(--color-success-400);
}

.Color-foreground-success-500 {
	color: var(--color-success-500);
}

.Color-foreground-success-600 {
	color: var(--color-success-600);
}

.Color-foreground-success-700 {
	color: var(--color-success-700);
}

.Color-foreground-success-800 {
	color: var(--color-success-800);
}

.Color-foreground-success-900 {
	color: var(--color-success-900);
}

.Color-foreground-success-1000 {
	color: var(--color-success-1000);
}

.Color-foreground-success-1100 {
	color: var(--color-success-1100);
}

.Color-foreground-warning-50 {
	color: var(--color-warning-50);
}

.Color-foreground-warning-100 {
	color: var(--color-warning-100);
}

.Color-foreground-warning-200 {
	color: var(--color-warning-200);
}

.Color-foreground-warning-300 {
	color: var(--color-warning-300);
}

.Color-foreground-warning-400 {
	color: var(--color-warning-400);
}

.Color-foreground-warning-500 {
	color: var(--color-warning-500);
}

.Color-foreground-warning-600 {
	color: var(--color-warning-600);
}

.Color-foreground-warning-700 {
	color: var(--color-warning-700);
}

.Color-foreground-warning-800 {
	color: var(--color-warning-800);
}

.Color-foreground-warning-900 {
	color: var(--color-warning-900);
}

.Color-foreground-warning-1000 {
	color: var(--color-warning-1000);
}

.Color-foreground-warning-1100 {
	color: var(--color-warning-1100);
}

.Color-foreground-info-50 {
	color: var(--color-info-50);
}

.Color-foreground-info-100 {
	color: var(--color-info-100);
}

.Color-foreground-info-200 {
	color: var(--color-info-200);
}

.Color-foreground-info-300 {
	color: var(--color-info-300);
}

.Color-foreground-info-400 {
	color: var(--color-info-400);
}

.Color-foreground-info-500 {
	color: var(--color-info-500);
}

.Color-foreground-info-600 {
	color: var(--color-info-600);
}

.Color-foreground-info-700 {
	color: var(--color-info-700);
}

.Color-foreground-info-800 {
	color: var(--color-info-800);
}

.Color-foreground-info-900 {
	color: var(--color-info-900);
}

.Color-foreground-info-1000 {
	color: var(--color-info-1000);
}

.Color-foreground-info-1100 {
	color: var(--color-info-1100);
}

.Color-foreground-teal-50 {
	color: var(--color-teal-50);
}

.Color-foreground-teal-100 {
	color: var(--color-teal-100);
}

.Color-foreground-teal-200 {
	color: var(--color-teal-200);
}

.Color-foreground-teal-300 {
	color: var(--color-teal-300);
}

.Color-foreground-teal-400 {
	color: var(--color-teal-400);
}

.Color-foreground-teal-500 {
	color: var(--color-teal-500);
}

.Color-foreground-teal-600 {
	color: var(--color-teal-600);
}

.Color-foreground-teal-700 {
	color: var(--color-teal-700);
}

.Color-foreground-teal-800 {
	color: var(--color-teal-800);
}

.Color-foreground-teal-900 {
	color: var(--color-teal-900);
}

.Color-foreground-teal-1000 {
	color: var(--color-teal-1000);
}

.Color-foreground-teal-1100 {
	color: var(--color-teal-1100);
}

.Color-foreground-pink-50 {
	color: var(--color-pink-50);
}

.Color-foreground-pink-100 {
	color: var(--color-pink-100);
}

.Color-foreground-pink-200 {
	color: var(--color-pink-200);
}

.Color-foreground-pink-300 {
	color: var(--color-pink-300);
}

.Color-foreground-pink-400 {
	color: var(--color-pink-400);
}

.Color-foreground-pink-500 {
	color: var(--color-pink-500);
}

.Color-foreground-pink-600 {
	color: var(--color-pink-600);
}

.Color-foreground-pink-700 {
	color: var(--color-pink-700);
}

.Color-foreground-pink-800 {
	color: var(--color-pink-800);
}

.Color-foreground-pink-900 {
	color: var(--color-pink-900);
}

.Color-foreground-pink-1000 {
	color: var(--color-pink-1000);
}

.Color-foreground-pink-1100 {
	color: var(--color-pink-1100);
}

.Color-foreground-orange-50 {
	color: var(--color-orange-50);
}

.Color-foreground-orange-100 {
	color: var(--color-orange-100);
}

.Color-foreground-orange-200 {
	color: var(--color-orange-200);
}

.Color-foreground-orange-300 {
	color: var(--color-orange-300);
}

.Color-foreground-orange-400 {
	color: var(--color-orange-400);
}

.Color-foreground-orange-500 {
	color: var(--color-orange-500);
}

.Color-foreground-orange-600 {
	color: var(--color-orange-600);
}

.Color-foreground-orange-700 {
	color: var(--color-orange-700);
}

.Color-foreground-orange-800 {
	color: var(--color-orange-800);
}

.Color-foreground-orange-900 {
	color: var(--color-orange-900);
}

.Color-foreground-orange-1000 {
	color: var(--color-orange-1000);
}

.Color-foreground-orange-1100 {
	color: var(--color-orange-1100);
}

.Color-foreground-purple-50 {
	color: var(--color-purple-50);
}

.Color-foreground-purple-100 {
	color: var(--color-purple-100);
}

.Color-foreground-purple-200 {
	color: var(--color-purple-200);
}

.Color-foreground-purple-300 {
	color: var(--color-purple-300);
}

.Color-foreground-purple-400 {
	color: var(--color-purple-400);
}

.Color-foreground-purple-500 {
	color: var(--color-purple-500);
}

.Color-foreground-purple-600 {
	color: var(--color-purple-600);
}

.Color-foreground-purple-700 {
	color: var(--color-purple-700);
}

.Color-foreground-purple-800 {
	color: var(--color-purple-800);
}

.Color-foreground-purple-900 {
	color: var(--color-purple-900);
}

.Color-foreground-purple-1000 {
	color: var(--color-purple-1000);
}

.Color-foreground-purple-1100 {
	color: var(--color-purple-1100);
}

.Color-foreground-green-50 {
	color: var(--color-green-50);
}

.Color-foreground-green-100 {
	color: var(--color-green-100);
}

.Color-foreground-green-200 {
	color: var(--color-green-200);
}

.Color-foreground-green-300 {
	color: var(--color-green-300);
}

.Color-foreground-green-400 {
	color: var(--color-green-400);
}

.Color-foreground-green-500 {
	color: var(--color-green-500);
}

.Color-foreground-green-600 {
	color: var(--color-green-600);
}

.Color-foreground-green-700 {
	color: var(--color-green-700);
}

.Color-foreground-green-800 {
	color: var(--color-green-800);
}

.Color-foreground-green-900 {
	color: var(--color-green-900);
}

.Color-foreground-green-1000 {
	color: var(--color-green-1000);
}

.Color-foreground-green-1100 {
	color: var(--color-green-1100);
}

.Color-foreground-coral-50 {
	color: var(--color-coral-50);
}

.Color-foreground-coral-100 {
	color: var(--color-coral-100);
}

.Color-foreground-coral-200 {
	color: var(--color-coral-200);
}

.Color-foreground-coral-300 {
	color: var(--color-coral-300);
}

.Color-foreground-coral-400 {
	color: var(--color-coral-400);
}

.Color-foreground-coral-500 {
	color: var(--color-coral-500);
}

.Color-foreground-coral-600 {
	color: var(--color-coral-600);
}

.Color-foreground-coral-700 {
	color: var(--color-coral-700);
}

.Color-foreground-coral-800 {
	color: var(--color-coral-800);
}

.Color-foreground-coral-900 {
	color: var(--color-coral-900);
}

.Color-foreground-coral-1000 {
	color: var(--color-coral-1000);
}

.Color-foreground-coral-1100 {
	color: var(--color-coral-1100);
}

.Color-foreground-blue-50 {
	color: var(--color-blue-50);
}

.Color-foreground-blue-100 {
	color: var(--color-blue-100);
}

.Color-foreground-blue-200 {
	color: var(--color-blue-200);
}

.Color-foreground-blue-300 {
	color: var(--color-blue-300);
}

.Color-foreground-blue-400 {
	color: var(--color-blue-400);
}

.Color-foreground-blue-500 {
	color: var(--color-blue-500);
}

.Color-foreground-blue-600 {
	color: var(--color-blue-600);
}

.Color-foreground-blue-700 {
	color: var(--color-blue-700);
}

.Color-foreground-blue-800 {
	color: var(--color-blue-800);
}

.Color-foreground-blue-900 {
	color: var(--color-blue-900);
}

.Color-foreground-blue-1000 {
	color: var(--color-blue-1000);
}

.Color-foreground-blue-1100 {
	color: var(--color-blue-1100);
}
