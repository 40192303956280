/**
 * Do not edit directly
 * Generated on Wed Dec 11 2024 08:21:42 GMT+0000 (Coordinated Universal Time)
 */

.Color-background-gray-50 {
	background: var(--color-gray-50);
}

.Color-background-gray-100 {
	background: var(--color-gray-100);
}

.Color-background-gray-200 {
	background: var(--color-gray-200);
}

.Color-background-gray-300 {
	background: var(--color-gray-300);
}

.Color-background-gray-400 {
	background: var(--color-gray-400);
}

.Color-background-gray-500 {
	background: var(--color-gray-500);
}

.Color-background-gray-600 {
	background: var(--color-gray-600);
}

.Color-background-gray-700 {
	background: var(--color-gray-700);
}

.Color-background-gray-800 {
	background: var(--color-gray-800);
}

.Color-background-gray-900 {
	background: var(--color-gray-900);
}

.Color-background-gray-1000 {
	background: var(--color-gray-1000);
}

.Color-background-gray-1100 {
	background: var(--color-gray-1100);
}

.Color-background-gray-000 {
	background: var(--color-gray-000);
}

.Color-background-neutral-50 {
	background: var(--color-neutral-50);
}

.Color-background-neutral-100 {
	background: var(--color-neutral-100);
}

.Color-background-neutral-200 {
	background: var(--color-neutral-200);
}

.Color-background-neutral-300 {
	background: var(--color-neutral-300);
}

.Color-background-neutral-400 {
	background: var(--color-neutral-400);
}

.Color-background-neutral-500 {
	background: var(--color-neutral-500);
}

.Color-background-neutral-600 {
	background: var(--color-neutral-600);
}

.Color-background-neutral-700 {
	background: var(--color-neutral-700);
}

.Color-background-neutral-800 {
	background: var(--color-neutral-800);
}

.Color-background-neutral-900 {
	background: var(--color-neutral-900);
}

.Color-background-neutral-1000 {
	background: var(--color-neutral-1000);
}

.Color-background-neutral-1100 {
	background: var(--color-neutral-1100);
}

.Color-background-neutral-000 {
	background: var(--color-neutral-000);
}

.Color-background-indigo-50 {
	background: var(--color-indigo-50);
}

.Color-background-indigo-100 {
	background: var(--color-indigo-100);
}

.Color-background-indigo-200 {
	background: var(--color-indigo-200);
}

.Color-background-indigo-300 {
	background: var(--color-indigo-300);
}

.Color-background-indigo-400 {
	background: var(--color-indigo-400);
}

.Color-background-indigo-500 {
	background: var(--color-indigo-500);
}

.Color-background-indigo-600 {
	background: var(--color-indigo-600);
}

.Color-background-indigo-700 {
	background: var(--color-indigo-700);
}

.Color-background-indigo-800 {
	background: var(--color-indigo-800);
}

.Color-background-indigo-900 {
	background: var(--color-indigo-900);
}

.Color-background-indigo-1000 {
	background: var(--color-indigo-1000);
}

.Color-background-indigo-1100 {
	background: var(--color-indigo-1100);
}

.Color-background-navy-50 {
	background: var(--color-navy-50);
}

.Color-background-navy-100 {
	background: var(--color-navy-100);
}

.Color-background-navy-200 {
	background: var(--color-navy-200);
}

.Color-background-navy-300 {
	background: var(--color-navy-300);
}

.Color-background-navy-400 {
	background: var(--color-navy-400);
}

.Color-background-navy-500 {
	background: var(--color-navy-500);
}

.Color-background-navy-600 {
	background: var(--color-navy-600);
}

.Color-background-navy-700 {
	background: var(--color-navy-700);
}

.Color-background-navy-800 {
	background: var(--color-navy-800);
}

.Color-background-navy-900 {
	background: var(--color-navy-900);
}

.Color-background-navy-1000 {
	background: var(--color-navy-1000);
}

.Color-background-navy-1100 {
	background: var(--color-navy-1100);
}

.Color-background-critical-50 {
	background: var(--color-critical-50);
}

.Color-background-critical-100 {
	background: var(--color-critical-100);
}

.Color-background-critical-200 {
	background: var(--color-critical-200);
}

.Color-background-critical-300 {
	background: var(--color-critical-300);
}

.Color-background-critical-400 {
	background: var(--color-critical-400);
}

.Color-background-critical-500 {
	background: var(--color-critical-500);
}

.Color-background-critical-600 {
	background: var(--color-critical-600);
}

.Color-background-critical-700 {
	background: var(--color-critical-700);
}

.Color-background-critical-800 {
	background: var(--color-critical-800);
}

.Color-background-critical-900 {
	background: var(--color-critical-900);
}

.Color-background-critical-1000 {
	background: var(--color-critical-1000);
}

.Color-background-critical-1100 {
	background: var(--color-critical-1100);
}

.Color-background-success-50 {
	background: var(--color-success-50);
}

.Color-background-success-100 {
	background: var(--color-success-100);
}

.Color-background-success-200 {
	background: var(--color-success-200);
}

.Color-background-success-300 {
	background: var(--color-success-300);
}

.Color-background-success-400 {
	background: var(--color-success-400);
}

.Color-background-success-500 {
	background: var(--color-success-500);
}

.Color-background-success-600 {
	background: var(--color-success-600);
}

.Color-background-success-700 {
	background: var(--color-success-700);
}

.Color-background-success-800 {
	background: var(--color-success-800);
}

.Color-background-success-900 {
	background: var(--color-success-900);
}

.Color-background-success-1000 {
	background: var(--color-success-1000);
}

.Color-background-success-1100 {
	background: var(--color-success-1100);
}

.Color-background-warning-50 {
	background: var(--color-warning-50);
}

.Color-background-warning-100 {
	background: var(--color-warning-100);
}

.Color-background-warning-200 {
	background: var(--color-warning-200);
}

.Color-background-warning-300 {
	background: var(--color-warning-300);
}

.Color-background-warning-400 {
	background: var(--color-warning-400);
}

.Color-background-warning-500 {
	background: var(--color-warning-500);
}

.Color-background-warning-600 {
	background: var(--color-warning-600);
}

.Color-background-warning-700 {
	background: var(--color-warning-700);
}

.Color-background-warning-800 {
	background: var(--color-warning-800);
}

.Color-background-warning-900 {
	background: var(--color-warning-900);
}

.Color-background-warning-1000 {
	background: var(--color-warning-1000);
}

.Color-background-warning-1100 {
	background: var(--color-warning-1100);
}

.Color-background-info-50 {
	background: var(--color-info-50);
}

.Color-background-info-100 {
	background: var(--color-info-100);
}

.Color-background-info-200 {
	background: var(--color-info-200);
}

.Color-background-info-300 {
	background: var(--color-info-300);
}

.Color-background-info-400 {
	background: var(--color-info-400);
}

.Color-background-info-500 {
	background: var(--color-info-500);
}

.Color-background-info-600 {
	background: var(--color-info-600);
}

.Color-background-info-700 {
	background: var(--color-info-700);
}

.Color-background-info-800 {
	background: var(--color-info-800);
}

.Color-background-info-900 {
	background: var(--color-info-900);
}

.Color-background-info-1000 {
	background: var(--color-info-1000);
}

.Color-background-info-1100 {
	background: var(--color-info-1100);
}

.Color-background-teal-50 {
	background: var(--color-teal-50);
}

.Color-background-teal-100 {
	background: var(--color-teal-100);
}

.Color-background-teal-200 {
	background: var(--color-teal-200);
}

.Color-background-teal-300 {
	background: var(--color-teal-300);
}

.Color-background-teal-400 {
	background: var(--color-teal-400);
}

.Color-background-teal-500 {
	background: var(--color-teal-500);
}

.Color-background-teal-600 {
	background: var(--color-teal-600);
}

.Color-background-teal-700 {
	background: var(--color-teal-700);
}

.Color-background-teal-800 {
	background: var(--color-teal-800);
}

.Color-background-teal-900 {
	background: var(--color-teal-900);
}

.Color-background-teal-1000 {
	background: var(--color-teal-1000);
}

.Color-background-teal-1100 {
	background: var(--color-teal-1100);
}

.Color-background-pink-50 {
	background: var(--color-pink-50);
}

.Color-background-pink-100 {
	background: var(--color-pink-100);
}

.Color-background-pink-200 {
	background: var(--color-pink-200);
}

.Color-background-pink-300 {
	background: var(--color-pink-300);
}

.Color-background-pink-400 {
	background: var(--color-pink-400);
}

.Color-background-pink-500 {
	background: var(--color-pink-500);
}

.Color-background-pink-600 {
	background: var(--color-pink-600);
}

.Color-background-pink-700 {
	background: var(--color-pink-700);
}

.Color-background-pink-800 {
	background: var(--color-pink-800);
}

.Color-background-pink-900 {
	background: var(--color-pink-900);
}

.Color-background-pink-1000 {
	background: var(--color-pink-1000);
}

.Color-background-pink-1100 {
	background: var(--color-pink-1100);
}

.Color-background-orange-50 {
	background: var(--color-orange-50);
}

.Color-background-orange-100 {
	background: var(--color-orange-100);
}

.Color-background-orange-200 {
	background: var(--color-orange-200);
}

.Color-background-orange-300 {
	background: var(--color-orange-300);
}

.Color-background-orange-400 {
	background: var(--color-orange-400);
}

.Color-background-orange-500 {
	background: var(--color-orange-500);
}

.Color-background-orange-600 {
	background: var(--color-orange-600);
}

.Color-background-orange-700 {
	background: var(--color-orange-700);
}

.Color-background-orange-800 {
	background: var(--color-orange-800);
}

.Color-background-orange-900 {
	background: var(--color-orange-900);
}

.Color-background-orange-1000 {
	background: var(--color-orange-1000);
}

.Color-background-orange-1100 {
	background: var(--color-orange-1100);
}

.Color-background-purple-50 {
	background: var(--color-purple-50);
}

.Color-background-purple-100 {
	background: var(--color-purple-100);
}

.Color-background-purple-200 {
	background: var(--color-purple-200);
}

.Color-background-purple-300 {
	background: var(--color-purple-300);
}

.Color-background-purple-400 {
	background: var(--color-purple-400);
}

.Color-background-purple-500 {
	background: var(--color-purple-500);
}

.Color-background-purple-600 {
	background: var(--color-purple-600);
}

.Color-background-purple-700 {
	background: var(--color-purple-700);
}

.Color-background-purple-800 {
	background: var(--color-purple-800);
}

.Color-background-purple-900 {
	background: var(--color-purple-900);
}

.Color-background-purple-1000 {
	background: var(--color-purple-1000);
}

.Color-background-purple-1100 {
	background: var(--color-purple-1100);
}

.Color-background-green-50 {
	background: var(--color-green-50);
}

.Color-background-green-100 {
	background: var(--color-green-100);
}

.Color-background-green-200 {
	background: var(--color-green-200);
}

.Color-background-green-300 {
	background: var(--color-green-300);
}

.Color-background-green-400 {
	background: var(--color-green-400);
}

.Color-background-green-500 {
	background: var(--color-green-500);
}

.Color-background-green-600 {
	background: var(--color-green-600);
}

.Color-background-green-700 {
	background: var(--color-green-700);
}

.Color-background-green-800 {
	background: var(--color-green-800);
}

.Color-background-green-900 {
	background: var(--color-green-900);
}

.Color-background-green-1000 {
	background: var(--color-green-1000);
}

.Color-background-green-1100 {
	background: var(--color-green-1100);
}

.Color-background-coral-50 {
	background: var(--color-coral-50);
}

.Color-background-coral-100 {
	background: var(--color-coral-100);
}

.Color-background-coral-200 {
	background: var(--color-coral-200);
}

.Color-background-coral-300 {
	background: var(--color-coral-300);
}

.Color-background-coral-400 {
	background: var(--color-coral-400);
}

.Color-background-coral-500 {
	background: var(--color-coral-500);
}

.Color-background-coral-600 {
	background: var(--color-coral-600);
}

.Color-background-coral-700 {
	background: var(--color-coral-700);
}

.Color-background-coral-800 {
	background: var(--color-coral-800);
}

.Color-background-coral-900 {
	background: var(--color-coral-900);
}

.Color-background-coral-1000 {
	background: var(--color-coral-1000);
}

.Color-background-coral-1100 {
	background: var(--color-coral-1100);
}

.Color-background-blue-50 {
	background: var(--color-blue-50);
}

.Color-background-blue-100 {
	background: var(--color-blue-100);
}

.Color-background-blue-200 {
	background: var(--color-blue-200);
}

.Color-background-blue-300 {
	background: var(--color-blue-300);
}

.Color-background-blue-400 {
	background: var(--color-blue-400);
}

.Color-background-blue-500 {
	background: var(--color-blue-500);
}

.Color-background-blue-600 {
	background: var(--color-blue-600);
}

.Color-background-blue-700 {
	background: var(--color-blue-700);
}

.Color-background-blue-800 {
	background: var(--color-blue-800);
}

.Color-background-blue-900 {
	background: var(--color-blue-900);
}

.Color-background-blue-1000 {
	background: var(--color-blue-1000);
}

.Color-background-blue-1100 {
	background: var(--color-blue-1100);
}
